import ArrowIcon from './ArrowIcon';
import StepItem from './StepItem';

export default function Description() {
  return (
    <div className="px-2 py-4 gap-3 items-center flex flex-col">
      <img
        src="/static/img/description.svg"
        alt="お絵かきAIが、あなたをイメージして描きます"
        className="block"
      />
      <div className="flex flex-col md:flex-row gap-[8px] md:gap-[17px] w-full items-center md:justify-center">
        <StepItem num={1} text="メールアドレスと簡単なプロフィールを入力！" />
        <ArrowIcon />
        <StepItem
          num={2}
          text="生成された画像をメールで受け取り"
          note="※少しお時間を要する場合がございます"
        />
        <ArrowIcon />
        <StepItem
          num={3}
          text={
            <>
              SNSアイコンや
              <br />
              プロフィール画像など、自由に使用できます
            </>
          }
          note="※商用利用可"
        />
      </div>
    </div>
  );
}
