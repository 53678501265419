import type { JSX } from 'react';

type Props = {
  num: number;
  text: JSX.Element | string;
  note?: string;
};

export default function StepItem(props: Props) {
  const stepImagePath = `/static/img/step0${props.num}.svg`;
  const stepIconPath = `/static/img/step0${props.num}-icon.svg`;

  return (
    <div className="flex md:flex-col items-center md:justify-start min-h-9.5 pl-1.5 pr-7 md:px-[12px] py-[12px] md:py-[16px] border-[#D7D7D7] border-[1px] rounded-md w-full md:w-[195px] md:h-[264px] relative md:static shadow-[0_0_10px_rgb(0_0_0_/_.05)]">
      <div className="pr-1 md:pr-0 md:pb-[16px] h-[71px] md:h-auto md:w-full flex items-center justify-center border-r-[1px] md:border-b-[1px] md:border-r-0 border-r-[#D7D7D7] border-b-[#D7D7D7">
        <img
          src={stepImagePath}
          alt={`ステップ${props.num}`}
          className="min-w-[38px] h-[41px]"
        />
      </div>
      <div className="pl-1.5 md:pl-0 md:pt-[16px] md:h-full flex flex-col">
        <p className="text-[16px] md:text-[15px]">{props.text}</p>
        {props.note ? <p className="text-[10px]">{props.note}</p> : null}
      </div>
      <img
        src={stepIconPath}
        alt="羽ペン"
        className="absolute md:static right-1.5 top-auto bottom-auto"
      />
    </div>
  );
}
