import {
  ActionFunctionArgs,
  LinksFunction,
  LoaderFunctionArgs,
  json,
  redirect,
} from "@remix-run/cloudflare";
import Description from "../components/Description";
import Hero, { heroStyles } from "../components/Hero";
import {
  ClientActionFunctionArgs,
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { z } from "zod";
import * as types from "../utils/types";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import {
  useForm,
  getFieldsetProps,
  getInputProps,
  getCollectionProps,
  getFormProps,
  getSelectProps,
} from "@conform-to/react";
import { Button } from "../components/buttons";
import { commitSession, getSession } from "../utils/sessions.server";
import { Turnstile } from "@marsidev/react-turnstile";
import { checkTurnstile } from "../utils/turnstile.server";
import { setUser as setSentryUser } from "@sentry/remix";

function createUserSchema() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const gafuSchema = z.nativeEnum(types.Gafu, { message: "選択してください" });
  const birthYearSchema = z
    .number()
    .int()
    .min(1900, { message: "1900年以降の年を選択してください" })
    .max(currentYear, { message: `過去の日付を設定してください` });
  const birthMonthSchema = z.number().int().min(1).max(12);
  const genderSchema = z.nativeEnum(types.Gender, {
    message: "選択してください",
  });
  const moneySchema = z.nativeEnum(types.Money, {
    message: "選択してください",
  });
  const interestsSchema = z
    .array(z.nativeEnum(types.Interests))
    .nonempty({ message: "1件以上選択してください" });

  return z
    .object({
      gafu: gafuSchema,
      birthYear: birthYearSchema,
      birthMonth: birthMonthSchema,
      gender: genderSchema,
      interests: interestsSchema,
      money: moneySchema,
      email: z
        .string({ message: "メールアドレスを入力してください" })
        .email({ message: "Invalid email address" }),
      privacyPolicyAgreements: z.boolean({
        message: "画像を生成するには、同意のチェックをする必要があります",
      }),
    })
    .refine(
      (data) => {
        // 入力された年月が現在の年月以下であることを確認
        if (data.birthYear > currentYear) return false;
        if (data.birthYear === currentYear && data.birthMonth > currentMonth)
          return false;
        return true;
      },
      { message: `過去の日付を設定してください` }
    );
}

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: heroStyles },
];

export async function loader({ request, context }: LoaderFunctionArgs) {
  const env = context.cloudflare.env;

  const session = await getSession(request.headers.get("Cookie"));

  let email: string | undefined = void 0;
  if (session.has("email")) {
    email = session.get("email");
  }

  return json(
    {
      email,
      turnstileSiteKey: env.TURNSTILE_SITE_KEY,
    },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    }
  );
}

export async function action({ request, context }: ActionFunctionArgs) {
  const cookie = request.headers.get("Cookie");

  if (!cookie) {
    // SameSite=Laxを設定してあるので、CookieがないということはCSRF攻撃の可能性がある
    throw new Response("Required cookie is missing.", { status: 400 });
  }

  const sentry = context.sentry;
  const env = context.cloudflare.env;

  const formData = await request.formData();
  const turnstileToken = formData.get("cf-turnstile-response");
  const ip = request.headers.get("CF-Connecting-IP");

  if (!turnstileToken) {
    throw new Response("Required turnstile token is missing.", { status: 400 });
  }

  try {
    await checkTurnstile(turnstileToken as string, ip, env.TURNSTILE_SECRET);
  } catch (e) {
    if (e instanceof Response) {
      if (e.status >= 500) {
        sentry.captureException(e);
      }
    } else {
      sentry.captureException(e);
    }
    throw e;
  }

  const submission = parseWithZod(formData, { schema: createUserSchema() });

  if (submission.status !== "success") {
    return submission.reply();
  }

  const {
    gafu,
    birthYear,
    birthMonth,
    gender,
    interests,
    money,
    email,
    privacyPolicyAgreements,
  } = submission.value;

  const birthMonthStr = birthMonth.toString().padStart(2, "0");

  try {
    const userInput = await env.DB.createUserInput(
      gafu,
      `${birthYear}-${birthMonthStr}-01`,
      birthYear,
      birthMonth,
      gender,
      interests,
      money,
      email,
      privacyPolicyAgreements
    );

    try {
      await env.KIRIBI.enqueue("USER_INPUT_TRANSFER_JOB", {
        userInputSlug: userInput.slug,
      });
    } catch (e) {
      if (
        (e as Error).message?.includes(
          "TypeError: Cannot read properties of undefined (reading 'hasOwnProperty')"
        )
      ) {
        console.warn(
          "enqueue USER_INPUT_TRANSFER_JOB",
          `enqueue時にエラーは起きましたがたぶん問題ないです (slug: ${userInput.slug})`
        );
      } else {
        console.error(
          "enqueue USER_INPUT_TRANSFER_JOB",
          `enqueue時にエラーが起きました (slug: ${userInput.slug})`,
          e
        );
      }
    }

    try {
      await env.KIRIBI.enqueue("CREATE_IMAGE_JOB", {
        userInputSlug: userInput.slug,
      });
    } catch (e) {
      if (
        (e as Error).message?.includes(
          "TypeError: Cannot read properties of undefined (reading 'hasOwnProperty')"
        )
      ) {
        console.warn(
          "enqueue CREATE_IMAGE_JOB",
          `enqueue時にエラーは起きましたがたぶん問題ないです (slug: ${userInput.slug})`
        );
      } else {
        console.error(
          "enqueue CREATE_IMAGE_JOB",
          `enqueue時にエラーが起きました (slug: ${userInput.slug})`,
          e
        );
      }
    }

    const session = await getSession(cookie);
    session.set("email", email);

    return redirect(`/thanks`, {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    });
  } catch (e) {
    console.error(e);
    return submission.reply({
      formErrors: ["送信に失敗しました"],
    });
  }
}

export async function clientAction({
  request,
  serverAction,
}: ClientActionFunctionArgs) {
  // request.formData() は二重実行ができないが、
  // serverAction() のローカル実行時にも内部で request.formData() が実行されるらしいので、
  // 先にcloneしておかないとエラーになる
  const copiedRequest = request.clone();
  const formData = await copiedRequest.formData();
  const email = formData.get("email");

  // serverAction() の実行中にサーバサイドでリダイレクトされた場合、
  // 戻り値を待たずに処理が中断されてリダイレクトされるので、
  // その前にユーザー情報をセットしておく
  if (email) {
    setSentryUser({ email: email as string });
  }

  const result = await serverAction();
  return result;
}

export default function IndexRoute() {
  const loaderData = useLoaderData<typeof loader>();
  const lastResult = useActionData<typeof action>();
  const navigation = useNavigation();
  const userSchema = createUserSchema();

  const [form, fields] = useForm({
    id: "form",
    lastResult,
    constraint: getZodConstraint(userSchema),
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: userSchema });
    },
  });

  const inputColor = (value: string | undefined) =>
    value ? "text-black" : "text-[#BEBEBE]";

  return (
    <article>
      <Hero formId={form.id} />
      <Description />
      <div className="bg-[#F3F3F3] px-2 py-4 md:flex md:justify-center">
        <Form
          method="POST"
          {...getFormProps(form)}
          className="flex flex-col gap-2 md:gap-2.5 w-full bg-white pt-2 md:pt-3 pb-3 md:pb-5 px-2 md:px-[28px] rounded-md shadow-[0_0_10px_rgba(0,0,0,0.05)] md:max-w-[680px]"
        >
          <div>
            <fieldset className="w-full" {...getFieldsetProps(fields.gafu)}>
              <legend className="block text-[15px] font-semibold mb-1">
                画風を選んでください
              </legend>
              <div className="flex flex-wrap gap-[12px] md:gap-[16px]">
                {getCollectionProps(fields.gafu, {
                  type: "radio",
                  options: gafuItems.map((item) => item.value),
                }).map((prop) => {
                  const { key, ...rest } = prop;
                  return (
                    <div key={key}>
                      <label>
                        <div>
                          <img
                            src={
                              gafuItems.find((i) => i.value === prop.value)
                                ?.imgSrc
                            }
                            className="w-[90px] h-[90px] rounded-md"
                            alt={
                              gafuItems.find((i) => i.value === prop.value)
                                ?.label
                            }
                          />
                        </div>
                        <div className="inline-flex items-center px-[2px] py-[4px]">
                          <input {...rest} className="form-radio" />
                          <label htmlFor={prop.id} className="ml-0.5">
                            {
                              gafuItems.find((i) => i.value === prop.value)
                                ?.label
                            }
                          </label>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
            {fields.gafu.errors && (
              <p id={fields.gafu.errorId} className="text-red-500 text-sm">
                {fields.gafu.errors}
              </p>
            )}
          </div>
          <div className="flex flex-col self-start w-full">
            <label
              htmlFor={fields.birthYear.id}
              className="block text-[15px] font-semibold mb-1"
            >
              生まれた年と月を入力してください
            </label>
            <div className="flex gap-2">
              <select
                {...getSelectProps(fields.birthYear)}
                defaultValue="2024"
                className={`h-[44px] bg-[#F8F8F8] px-1.5 py-0.5 border border-[#C6C6C6] focus:outline-none focus:ring-[#FF4B00] focus:border-[#FF4B00] rounded-md ${inputColor(
                  fields.birthYear.value
                )}`}
              >
                {birthYearItems().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <span className="flex items-center justify-center">年</span>
              <select
                {...getSelectProps(fields.birthMonth)}
                defaultValue="1"
                className={`h-[44px] bg-[#F8F8F8] px-1.5 py-0.5 border border-[#C6C6C6] focus:outline-none focus:ring-[#FF4B00] focus:border-[#FF4B00] rounded-md ${inputColor(
                  fields.birthMonth.value
                )}`}
              >
                {birthMonthItems.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <span className="flex items-center justify-center">月</span>
            </div>
            {fields.birthYear && (
              <p className="text-red-500 text-sm">{fields.birthYear.errors}</p>
            )}
            {fields.birthMonth && (
              <p className="text-red-500 text-sm">{fields.birthMonth.errors}</p>
            )}
          </div>
          <div>
            <fieldset className="w-full" {...getFieldsetProps(fields.gender)}>
              <legend className="block text-[15px] font-semibold mb-1">
                性別を選んでください
              </legend>
              <div className="flex gap-2.5">
                {getCollectionProps(fields.gender, {
                  type: "radio",
                  options: genderItems.map((item) => item.value),
                }).map((prop) => {
                  const { key, ...rest } = prop;
                  return (
                    <div key={key}>
                      <label className="inline-flex items-center">
                        <input {...rest} className="form-radio" />
                        <label htmlFor={prop.id} className="ml-1">
                          {
                            genderItems.find((i) => i.value === prop.value)
                              ?.label
                          }
                        </label>
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
            {fields.gender && (
              <p className="text-red-500 text-sm">{fields.gender.errors}</p>
            )}
          </div>
          <div>
            <fieldset className="w-full">
              <legend className="block text-[15px] font-semibold mb-1">
                興味のあること
              </legend>
              <div className="flex flex-col md:flex-row md:flex-wrap md:gap-x-[24px]">
                {getCollectionProps(fields.interests, {
                  type: "checkbox",
                  options: interestsItems.map((item) => item.value),
                }).map((prop) => {
                  const { key, ...rest } = prop;
                  return (
                    <div key={key}>
                      <label className="inline-flex items-center">
                        <input {...rest} className="form-checkbox" />
                        <label htmlFor={prop.id} className="ml-1 py-0.5">
                          {
                            interestsItems.find((i) => i.value === prop.value)
                              ?.label
                          }
                        </label>
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
            {fields.interests && (
              <p className="text-red-500 text-sm">{fields.interests.errors}</p>
            )}
          </div>
          <div>
            <label
              htmlFor={fields.money.id}
              className="block text-[15px] font-semibold mb-1"
            >
              お金の使い方
            </label>
            <select
              {...getSelectProps(fields.money)}
              defaultValue=""
              className={`w-full h-[44px] bg-[#F8F8F8] px-1.5 py-0.5 border border-[#C6C6C6] focus:outline-none focus:ring-[#FF4B00] focus:border-[#FF4B00] rounded-md ${inputColor(
                fields.money.value
              )}`}
            >
              <option value="" disabled>
                選択してください
              </option>
              {moneyItems.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {fields.money && (
              <p className="text-red-500 text-sm">{fields.money.errors}</p>
            )}
          </div>
          <div>
            <label
              htmlFor={fields.money.id}
              className="block text-[15px] font-semibold mb-1"
            >
              メールアドレス
            </label>
            <input
              {...getInputProps(fields.email, { type: "email" })}
              defaultValue={loaderData?.email}
              placeholder="例）abcd1234＠gmail.com"
              className="w-full h-[44px] bg-[#F8F8F8] px-1.5 py-0.5 border border-[#C6C6C6] focus:outline-none focus:ring-[#FF4B00] focus:border-[#FF4B00] rounded-md focus:text-black placeholder-[#BEBEBE] selection:text-[#FF4B00]"
            />
            {fields.email && (
              <p className="text-red-500 text-sm">{fields.email.errors}</p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-[8px]">
              <label
                htmlFor={fields.money.id}
                className="block text-[13px] font-semibold"
              >
                プライバシーポリシーへ同意をお願いします
              </label>
              <p className="w-full">
                <a
                  href="https://navipla.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-[4px]"
                >
                  <span className="text-[#0093FF] text-[14px] underline">
                    プライバシーポリシーはこちら
                  </span>
                  <img
                    src="/static/img/ic_external-link.svg"
                    className="w-[24px] h-[24px] inline-block"
                    alt="外部リンク"
                  />
                </a>
              </p>
            </div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                name={fields.privacyPolicyAgreements.name}
                id={fields.privacyPolicyAgreements.id}
              />
              <label
                htmlFor={fields.privacyPolicyAgreements.id}
                className="ml-1 text-[13px]"
              >
                同意の上、メールマガジン配信を了承する
              </label>
            </label>
            {fields.privacyPolicyAgreements && (
              <p className="text-red-500 text-sm">
                {fields.privacyPolicyAgreements.errors}
              </p>
            )}
          </div>
          <div className="flex flex-col items-center">
            <Button
              type="submit"
              disabled={navigation.state === "submitting"}
              fullRound
              chevronRight
            >
              {navigation.state === "submitting"
                ? "送信中..."
                : "無料で生成する"}
            </Button>
          </div>
          {form.errors && (
            <div id={form.errorId} className="text-red-500">
              {form.errors}
            </div>
          )}
          <div className="flex justify-center">
            <Turnstile siteKey={loaderData.turnstileSiteKey} />
          </div>
        </Form>
      </div>
    </article>
  );
}

const gafuItems = [
  {
    value: types.Gafu.Anime,
    label: "アニメ風",
    imgSrc: "/static/img/thumb_anime.png",
  },
  {
    value: types.Gafu.PixelArt,
    label: "ドット絵",
    imgSrc: "/static/img/thumb_pixel-art.png",
  },
  {
    value: types.Gafu.Realistic,
    label: "リアル",
    imgSrc: "/static/img/thumb_realistic.png",
  },
  {
    value: types.Gafu.AmericanComicsStyle,
    label: "アメコミ",
    imgSrc: "/static/img/thumb_american-comics-style.png",
  },
  {
    value: types.Gafu.WaterColor,
    label: "水彩画",
    imgSrc: "/static/img/thumb_water-color.png",
  },
  {
    value: types.Gafu.PopArt,
    label: "ポップ",
    imgSrc: "/static/img/thumb_pop-art.png",
  },
  // { value: types.Gafu.Random, label: "おまかせ" },
];

// 当年から1900年までの年の配列を生成
// トップレベルのスコープで定義すると、new Date()が1970年1月1日になるので、関数として都度実行する
const birthYearItems = (currentYear = new Date().getFullYear()) =>
  Array.from({ length: currentYear - 1900 + 1 }, (_, i) => currentYear - i);

// 月の配列を生成
const birthMonthItems = Array.from({ length: 12 }, (_, i) => i + 1);

const genderItems = [
  { value: types.Gender.Female, label: "女性" },
  { value: types.Gender.Male, label: "男性" },
  { value: types.Gender.Others, label: "その他" },
];

const interestsItems = [
  {
    value: types.Interests.AssetFormation,
    label: "お金・資産形成・資産運用",
  },
  { value: types.Interests.Travel, label: "旅行" },
  { value: types.Interests.Foodie, label: "グルメ" },
  { value: types.Interests.Career, label: "仕事・キャリア" },
  { value: types.Interests.Fashion, label: "ファッション" },
  { value: types.Interests.Pet, label: "ペット" },
  { value: types.Interests.Entertainment, label: "エンタメ" },
  { value: types.Interests.Shopping, label: "買い物" },
];

const moneyItems = [
  {
    value: types.Money.Steady,
    label: "堅実にコツコツ貯める！",
  },
  {
    value: types.Money.Splurge,
    label: "パーっと使うのが好き！",
  },
  { value: types.Money.Investor, label: "投資が大好き！" },
  { value: types.Money.WorkingHard, label: "働いて稼ぐよ！" },
  { value: types.Money.Thrifty, label: "節約大好き！" },
  {
    value: types.Money.Gambler,
    label: "ついギャンブルやっちゃうな〜",
  },
];
