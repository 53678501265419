import heroStyles from "../styles/hero.css?url";
import { LinkButton } from "./buttons";
export { heroStyles };

export default function Hero({ formId }: { formId: string }) {
  return (
    <div className="relative overflow-hidden">
      {/* 背景アニメーション用のレイヤー（z-index: 0） */}
      <div className="absolute inset-0 scroll-bg" />
      {/* 飾り用のレイヤー（z-index: 10） */}
      <div className="absolute inset-0 z-10 bg-[rgb(0_0_0_/_.6)]">
        {/* 右上の飾り */}
        <picture>
          <source
            srcSet="/static/img/hero-triangle-topright-pc.svg"
            width="339"
            height="92"
            className="absolute top-0 right-0"
            media="(min-width: 768px)"
          />
          <img
            src="/static/img/hero-triangle-topright-sp.svg"
            width="215"
            height="113"
            className="absolute top-0 right-0"
            alt="飾り"
          />
        </picture>

        {/* 右下の飾り */}
        <picture>
          <source
            srcSet="/static/img/hero-triangle-bottomright-pc.svg"
            width="693"
            height="92"
            className="absolute right-0 bottom-0 w-[55%]"
            media="(min-width: 768px)"
          />
          <img
            src="/static/img/hero-triangle-bottomright-sp.svg"
            width="237"
            height="99"
            className="absolute right-0 bottom-0 w-[55%]"
            alt="飾り"
          />
        </picture>

        {/* 左下の飾り */}
        <picture>
          <source
            srcSet="/static/img/hero-triangle-bottomleft-pc.svg"
            width="567"
            height="151"
            className="absolute left-0 bottom-0 w-[45%]"
            media="(min-width: 768px)"
          />
          <img
            src="/static/img/hero-triangle-bottomleft-sp.svg"
            width="138"
            height="124"
            className="absolute left-0 bottom-0 w-[45%]"
            alt="飾り"
          />
        </picture>
      </div>
      {/* コンテンツ用のレイヤー（z-index: 20） */}
      <div className="relative z-20 flex flex-col gap-6.5 md:gap-3 items-center pb-6.5 md:pb-5.5 pt-13 md:pt-7">
        <picture>
          {/* AVIF */}
          <source
            srcSet="/static/img/logo-pc.avif 1x, /static/img/logo-pc@2x.avif 2x"
            type="image/avif"
            media="(min-width: 768px)"
            width="470"
            height="199"
          />
          <source
            srcSet="/static/img/logo-sp.avif 1x, /static/img/logo-sp@2x.avif 2x"
            type="image/avif"
            width="333"
            height="149"
          />
          {/* WebP */}
          <source
            srcSet="/static/img/logo-pc.webp 1x, /static/img/logo-pc@2x.webp 2x"
            type="image/webp"
            media="(min-width: 768px)"
            width="470"
            height="199"
          />
          <source
            srcSet="/static/img/logo-sp.webp 1x, /static/img/logo-sp@2x.webp 2x"
            type="image/webp"
            width="333"
            height="149"
          />
          {/* PNG */}
          <source
            srcSet="/static/img/logo-pc.png 1x, /static/img/logo-pc@2x.png 2x"
            type="image/png"
            media="(min-width: 768px)"
            width="470"
            height="199"
          />
          <source
            srcSet="/static/img/logo-sp.png 1x, /static/img/logo-sp@2x.png 2x"
            type="image/png"
            width="333"
            height="149"
          />
          {/* デフォルト */}
          <img
            src="/static/img/logo-sp.png"
            srcSet="/static/img/logo-sp.png 1x, /static/img/logo-sp@2x.png 2x"
            width="333"
            height="149"
            alt="プロフィールをもとにAIがイメージしたあなたの姿は？ キャラつくAI"
          />
        </picture>
        <div className="flex flex-col gap-1 w-full">
          <span className="text-[16.2px] font-semibold text-white block self-center">
            {"\uff3c"} なんどでも無料 {"\uff0f"}
          </span>
          <LinkButton href={"#" + formId} chevronRight dark>
            生成してみる
          </LinkButton>
        </div>
      </div>
    </div>
  );
}
