export const Gafu = {
  Anime: '1',
  PixelArt: '2',
  WaterColor: '3',
  AmericanComicsStyle: '4',
  Realistic: '5',
  PopArt: '6',
  Random: '7',
} as const;

export const Gender = {
  Female: '1',
  Male: '2',
  Others: '3',
} as const;

export const Money = {
  Steady: '1',
  Splurge: '2',
  Investor: '3',
  WorkingHard: '4',
  Thrifty: '5',
  Gambler: '6',
} as const;

export const Interests = {
  AssetFormation: '1',
  Travel: '2',
  Foodie: '3',
  Career: '4',
  Fashion: '5',
  Pet: '6',
  Entertainment: '7',
  Shopping: '8',
} as const;

export type Gafu = (typeof Gafu)[keyof typeof Gafu];
export type Gender = (typeof Gender)[keyof typeof Gender];
export type Money = (typeof Money)[keyof typeof Money];
export type Interests = (typeof Interests)[keyof typeof Interests];
